import React, { useState } from "react"
import axios from "axios"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BG from "../../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"

// Funding Request - Google form
// mazdaaustraliafoundation@gmail.com
const FORM =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfZtKV8q1SNqfLU732X9UZ_7AHiEiNq3MAcX_DdewzpQQwqBg/formResponse"
const DEDUCTIBLE_GIFT_RECIPIENT = "entry.773527156"
const ORGANISATION_NAME = "entry.1251610967"
const ORGANISATION_WEBSITE = "entry.2056850418"
const AREA_OF_FOCUS = "entry.1496774083"
const DESCRIPTION_FOCUS = "entry.1934864825"
const ADDRESS = "entry.24236829"
const SUBURB = "entry.1415790145"
const POSTCODE = "entry.460928311"
const ABN1 = "entry.1050983755"
const STATE1 = "entry.2069354572"

const TITLE = "entry.1152419267"
const FIRST_NAME = "entry.1245007574"
const SURNAME = "entry.1213595461"
const POSITION = "entry.688640723"
const TELEPHONE_NUMBER = "entry.1698951203"
const EMAIL = "emailAddress"

const DATE_GIFT_YEAR = "entry.299868322_year"
const DATE_GIFT_MONTH = "entry.299868322_month"
const DATE_GIFT_DAY = "entry.299868322_day"
const DATE_TAX_YEAR = "entry.252218403_year"
const DATE_TAX_MONTH = "entry.252218403_month"
const DATE_TAX_DAY = "entry.252218403_day"

const NO_PAID_STAFF = "entry.442066658"
const NO_VOLUNTEERS = "entry.1560014025"
const NO_BOARD_MEMBERS = "entry.1037249577"
const PRIOR_YEAR_REVENUE = "entry.2059188085"
const PRIOR_YEAR_EXPENDITURE = "entry.1567977874"
const PRIOR_YEAR_REV_GOV = "entry.260616099"
const PRIOR_YEAR_REV_PHIL = "entry.1542440989"

const DESCRIPTION_ORGANISATION = "entry.168110393"
const PROJECT_NEED = "entry.369449608"
const DESCRIPTION_PROJECT = "entry.482856345"
const DESCRIPTION_EXPERIENCE = "entry.609773738"
const EXPECTED_OUTCOMES = "entry.456087611"
const DESCRIPTION_OUTCOMES = "entry.1900122338"

const TOTAL_BUDGET_AMOUNT = "entry.311714988"
const MF_REQUEST_AMOUNT = "entry.1748769527"
const OTHER_INCOME = "entry.192692394"
const PROJECT_EXPENDITURE = "entry.506365827"

const DATE_YEAR = "entry.891392417_year"
const DATE_MONTH = "entry.891392417_month"
const DATE_DAY = "entry.891392417_day"


const FundingRequest = () => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)
  const [isDeductibleGiftRecipient, setIsDeductibleGiftRecipient] = useState("")
  const [organisationName, setOrganisationName] = useState("")
  const [organisationWebsite, setOrganisationWebsite] = useState("")
  const [areaOfFocus, setAreaOfFocus] = useState("")
  const [descriptionFocus, setDescriptionFocus] = useState("")
  const [address, setAddress] = useState("")
  const [suburb, setSuburb] = useState("")
  const [postcode, setPostcode] = useState("")
  const [state1, setState1] = useState("")
  const [ABN, setABN] = useState("")
  const [title, setTitle] = useState("")
  const [firstName, setFirstName] = useState("")
  const [surname, setSurname] = useState("")
  const [position, setPosition] = useState("")
  const [telephoneNumber, setTelephoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [dateForGiftReceipt, setDateForGiftReceipt] = useState("")
  const [dateForIncomeTax, setDateForIncomeTax] = useState("")
  const [noOfPaidStaff, setNoOfPaidStaff] = useState("")
  const [noOfVolunteers, setNoOfVolunteers] = useState("")
  const [currentBoardMembers, setCurrentBoardMembers] = useState("")
  const [priorYearRevenue, setPriorYearRevenue] = useState("")
  const [priorYearExpenditure, setPriorYearExpenditure] = useState("")
  const [priorYearRevenueGov, setPriorYearRevenueGov] = useState("")
  const [priorYearRevenuePhilanthropic, setPriorYearRevenuePhilanthropic] = useState("")
  const [descriptionOrganisation, setDescriptionOrganisation] = useState("")
  const [needToBeAddressedBy, setNeedToBeAddressedBy] = useState("")
  const [descriptionProject, setDescriptionProject] = useState("")
  const [descriptionExperience, setDescriptionExperience] = useState("")
  const [expectedOutcomeOfProject, setExpectedOutcomeOfProject] = useState("")
  const [descriptionOutcomes, setDescriptionOutcomes] = useState("")
  const [totalProjectBudgetAmount, setTotalProjectBudgetAmount] = useState("")
  const [mfRequestAmount, setMFRequestAmount] = useState("")
  const [otherIncome, setOtherIncome] = useState("")
  const [projectExpenditure, setProjectExpenditure] = useState("")
  const [date, setDate] = useState("")

  return (
    <Layout page="funding-request">
      <SEO title="Funding Request" />
      <div
        id="funding-request"
        style={{
          background: `url(${BG}) repeat`,
        }}
      >
        <div id="title" className="green-bg vertical-padding-small center">
          <div className="container">
            <div className="horizontal-padding-large">
              <h1>PRIORITY AREAS OF FOCUS</h1>
              <div>
                <h3>Primary Producers facing hardship</h3>
                <p>Community based organisations with projects supporting primary producers facing hardship.</p>
              </div>
              <div>
                <h3>Literacy and Numeracy</h3>
                <p>Programs addressing literacy and numeracy issues amongst primary school aged children, especially remedial programs for children from disadvantaged areas.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="vertical-padding-small">
          <div className="container">
            <div id="funding-request" className="horizontal-padding">
              <form
                method="POST"
                onSubmit={e => {
                  e.preventDefault()
                  if (!loading) {
                    const formData = new FormData()
                    formData.append(DEDUCTIBLE_GIFT_RECIPIENT, isDeductibleGiftRecipient)
                    formData.append(AREA_OF_FOCUS, areaOfFocus)
                    formData.append(ORGANISATION_NAME, organisationName)
                    formData.append(ORGANISATION_WEBSITE, organisationWebsite)
                    formData.append(DESCRIPTION_FOCUS, descriptionFocus)
                    formData.append(ADDRESS, address)
                    formData.append(SUBURB, suburb)
                    formData.append(POSTCODE, postcode)
                    formData.append(STATE1, state1)
                    formData.append(ABN1, ABN)
                    formData.append(TITLE, title)
                    formData.append(FIRST_NAME, firstName)
                    formData.append(SURNAME, surname)
                    formData.append(POSITION, position)
                    formData.append(TELEPHONE_NUMBER, telephoneNumber)
                    formData.append(EMAIL, email)
                    formData.append(DATE_GIFT_YEAR, dateForGiftReceipt.split("-")[0])
                    formData.append(DATE_GIFT_MONTH, dateForGiftReceipt.split("-")[1])
                    formData.append(DATE_GIFT_DAY, dateForGiftReceipt.split("-")[2])
                    formData.append(DATE_TAX_YEAR, dateForIncomeTax.split("-")[0])
                    formData.append(DATE_TAX_MONTH, dateForIncomeTax.split("-")[1])
                    formData.append(DATE_TAX_DAY, dateForIncomeTax.split("-")[2])
                    formData.append(NO_PAID_STAFF, noOfPaidStaff)
                    formData.append(NO_VOLUNTEERS, noOfVolunteers)
                    formData.append(NO_BOARD_MEMBERS, currentBoardMembers)
                    formData.append(PRIOR_YEAR_REVENUE, priorYearRevenue)
                    formData.append(PRIOR_YEAR_EXPENDITURE, priorYearExpenditure)
                    formData.append(PRIOR_YEAR_REV_GOV, priorYearRevenueGov)
                    formData.append(PRIOR_YEAR_REV_PHIL, priorYearRevenuePhilanthropic)
                    formData.append(DESCRIPTION_ORGANISATION, descriptionOrganisation)
                    formData.append(DESCRIPTION_PROJECT, descriptionProject)
                    formData.append(PROJECT_NEED, needToBeAddressedBy)
                    formData.append(DESCRIPTION_EXPERIENCE, descriptionExperience)
                    formData.append(EXPECTED_OUTCOMES, expectedOutcomeOfProject)
                    formData.append(DESCRIPTION_OUTCOMES, descriptionOutcomes)
                    formData.append(TOTAL_BUDGET_AMOUNT, totalProjectBudgetAmount)
                    formData.append(MF_REQUEST_AMOUNT, mfRequestAmount)
                    formData.append(OTHER_INCOME, otherIncome)
                    formData.append(PROJECT_EXPENDITURE, projectExpenditure)
                    formData.append(DATE_YEAR, date.split("-")[0])
                    formData.append(DATE_MONTH, date.split("-")[1])
                    formData.append(DATE_DAY, date.split("-")[2])
                    formData.append("entry.773527156_sentinel", "")
                    formData.append("entry.1496774083_sentinel", "")
                    formData.append("entry.2069354572_sentinel", "")

                    setLoading(true)
                    axios
                      .post(FORM, formData)
                      .then(response => {
                        setLoading(false)
                        setResult("success")
                      })
                      .catch(error => {
                        setLoading(false)
                        setResult("success")
                      })
                  }
                }}
              >

              <div className="columns">
                  <div className="column">
                    <h3>
                      <b>ELIGIBILITY</b>
                    </h3>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="isDeductibleGiftRecipient">
                        <b>DOES YOUR ORGANISATION HAVE ENDORSEMENT AS A DEDUCTIBLE GIFT RECIPIENT (DGR)? *</b>
                      </label>
                      <br />
                      </p>
                  </div>
                  <div className="one_fourth column">
                      <select
                        name="isDeductibleGiftRecipient"
                        value={isDeductibleGiftRecipient}
                        onChange={e => setIsDeductibleGiftRecipient(e.target.value)}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="" />
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      </div> 
                </div>
                {/*   */}
                {isDeductibleGiftRecipient != "No" && <div>
                
                <div className="columns">
                <div className="column">
                    <hr className="hr hr2" />
                  </div>
                  <div className="column">
                    <h3>
                      <b>ORGANISATION DETAILS</b>
                    </h3>
                  </div>
                  </div>
                  <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="areaOfFocus">
                        <b>AREA OF FOCUS *</b>
                      </label>
                      <br />
                      <select
                        name="areaOfFocus"
                        value={areaOfFocus}
                        onChange={e => setAreaOfFocus(e.target.value)}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="" />
                        <option value="Primary Produces facing hardship">Primary Produces facing hardship</option>
                        <option value="Literacy and Numeracy">Literacy and Numeracy</option>
                      </select>
                    </p>
                  </div>
                </div>
                  <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="organisationName">
                        <b>ORGANISATION NAME *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="organisationName"
                        value={organisationName}
                        onChange={e => setOrganisationName(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="organisationWebsite">
                        <b>ORGANISATION WEBSITE *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="organisationWebsite"
                        value={organisationWebsite}
                        onChange={e => setOrganisationWebsite(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <p>
                      <label htmlFor="descriptionFocus">
                        <b>
                          TELL US HOW THE PROJECT RELATES TO THE PRIORITY AREA OF FOCUS.
                          <br />
                          Maximum 300 characters. *</b>
                      </label>
                      <br />
                      <textarea
                        name="descriptionFocus"
                        value={descriptionFocus}
                        onChange={e =>
                          setDescriptionFocus(e.target.value)
                        }
                        rows="10"
                        maxlength={300}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="address">
                        <b>ADDRESS *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_fourth column">
                    <p>
                      <label htmlFor="suburb">
                        <b>SUBURB *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="suburb"
                        value={suburb}
                        onChange={e => setSuburb(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_fourth column">
                    <p>
                      <label htmlFor="postcode">
                        <b>POSTCODE *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="postcode"
                        value={postcode}
                        onChange={e => setPostcode(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_fourth column">
                    <p>
                      <label htmlFor="state1">
                        <b>STATE *</b>
                      </label>
                      <br />
                      <select
                        name="state1"
                        value={state1}
                        onChange={e => setState1(e.target.value)}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="" />
                        <option value="VIC">VIC</option>
                        <option value="NSW">NSW</option>
                        <option value="QLD">QLD</option>
                        <option value="ACT">ACT</option>
                        <option value="NT">NT</option>
                        <option value="SA">SA</option>
                        <option value="WA">WA</option>
                        <option value="TAS">TAS</option>
                      </select>
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="ABN">
                        <b>ABN REGISTRATION NUMBER *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="ABN"
                        value={ABN}
                        onChange={e => setABN(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <hr className="hr hr2" />
                  </div>
                  <div className="column">
                    <h3>
                      <b>CONTACT PERSON DETAILS</b>
                    </h3>
                  </div>
                  <div className="one_fourth column">
                    <p>
                      <label htmlFor="title">
                        <b>TITLE *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_fourth column">
                    <p>
                      <label htmlFor="firstName">
                        <b>FIRST NAME *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_fourth column">
                    <p>
                      <label htmlFor="surname">
                        <b>SURNAME *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="surname"
                        value={surname}
                        onChange={e => setSurname(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="position">
                        <b>POSITION</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="position"
                        value={position}
                        onChange={e => setPosition(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="telephoneNumber">
                        <b>TELEPHONE NUMBER *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="telephoneNumber"
                        value={telephoneNumber}
                        onChange={e => setTelephoneNumber(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="email">
                        <b>EMAIL *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <hr className="hr hr2" />
                  </div>
                  <div className="column">
                    <h3>
                      <b>FUNDING DETAILS</b>
                    </h3>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="dateForGiftReceipt">
                        <b>
                          DATE OF ISSUE OF ENDORSEMENT AS A DEDUCTIBLE GIFT
                          RECIPIENT
                          *</b>
                      </label>
                      <br />
                      <input
                        type="date"
                        name="dateForGiftReceipt"
                        value={dateForGiftReceipt}
                        onChange={e => setDateForGiftReceipt(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="dateForIncomeTax">
                        <b>
                          DATE OF ISSUE OF ENDORSEMENT AS AN INCOME TAX EXEMPT CHARITY
                          *</b>
                      </label>
                      <br />
                      <input
                        type="date"
                        name="dateForIncomeTax"
                        value={dateForIncomeTax}
                        onChange={e => setDateForIncomeTax(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="noOfPaidStaff">
                        <b>
                        NUMBER OF PAID STAFF (FTE EQUIVILENT) *</b>
                      </label>
                      <br />
                      <input
                        type="text" 
                        name="noOfPaidStaff"
                        pattern="^[0-9\b]+$"
                        value={noOfPaidStaff}
                        onChange={e => setNoOfPaidStaff(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="noOfVolunteers">
                        <b>
                        NUMBER OF VOLUNTEERS *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="noOfVolunteers"
                        pattern="^[0-9\b]+$"
                        value={noOfVolunteers}
                        onChange={e => setNoOfVolunteers(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  
                  <div className="column">
                    <p>
                      <label htmlFor="currentBoardMembers">
                        <b>
                        PROVIDE A LINK TO OR LIST NAMES OF CURRENT BOARD MEMBERS.
                        <br />
                          Maximum 1,200 characters. *</b>
                      </label>
                      <br />
                      <textarea
                        name="currentBoardMembers"
                        pattern="^[0-9\b]+$"
                        value={currentBoardMembers}
                        onChange={e =>
                          setCurrentBoardMembers(e.target.value)
                        }
                        rows="10"
                        maxlength={1200}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                
                  <div className="one_half column">
                    <p>
                      <label htmlFor="priorYearRevenue">
                        <b>
                        TOTAL PRIOR YEAR REVENUE/INCOME *</b>
                      </label>
                      <br />
                      <input
                        type="text" 
                        name="priorYearRevenue"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={priorYearRevenue}
                        onChange={e => setPriorYearRevenue(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="priorYearExpenditure">
                        <b>
                        TOTAL PRIOR YEAR EXPENDITURE *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="priorYearExpenditure"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={priorYearExpenditure}
                        onChange={e => setPriorYearExpenditure(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="priorYearRevenueGov">
                        <b>
                        ANNUAL PRIOR YEAR REVENUE FROM ALL GOVERNMENT SOURCES *</b>
                      </label>
                      <br />
                      <input
                        type="text" 
                        name="priorYearRevenueGov"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={priorYearRevenueGov}
                        onChange={e => setPriorYearRevenueGov(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="one_half column">
                    <p>
                      <label htmlFor="priorYearRevenuePhilanthropic">
                        <b>
                        ANNUAL PRIOR YEAR REVENUE FROM PHILANTHROPIC SOURCES *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="priorYearRevenuePhilanthropic"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={priorYearRevenuePhilanthropic}
                        onChange={e => setPriorYearRevenuePhilanthropic(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="descriptionOrganisation">
                        <b>
                        WHAT IS THE PURPOSE OR MISSION OF YOUR ORGANISATION ?
                          <br />
                           Maximum 900 characters. *</b>
                      </label>
                      <br />
                      <textarea
                        name="descriptionOrganisation"
                        value={descriptionOrganisation}
                        onChange={e =>
                          setDescriptionOrganisation(e.target.value)
                        }
                        rows="10"
                        maxLength={900}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="descriptionProject">
                        <b>
                          TELL US ABOUT THE SPECIFIC PROJECT FOR WHICH ARE
                          SEEKING MAZDA FOUNDATION FUNDING
                          <br />
                          Provide a short background on the objectives of the
                          project,{" "}
                          <u>who and how many will benefit from funding</u>, the
                          time frame for implementation and how it fits within
                          the Mazda Foundation area of focus. Maximum 1,800 characters.
                          *</b>
                      </label>
                      <br />
                      <textarea
                        name="descriptionProject"
                        value={descriptionProject}
                        onChange={e => setDescriptionProject(e.target.value)}
                        rows="10"
                        maxLength={1800}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="needToBeAddressedBy">
                        <b>
                        TELL US ABOUT THE NEED TO BE ADDRESSED BY THIS PROJECT
                          <br />
                          Maximum 1,500 characters. *</b>
                      </label>
                      <br />
                      <textarea
                        name="needToBeAddressedBy"
                        value={needToBeAddressedBy}
                        onChange={e => setNeedToBeAddressedBy(e.target.value)}
                        rows="10"
                        maxLength={1500}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="descriptionExperience">
                        <b>
                          WHAT EXPERIENCE DOES YOUR ORGANISATION HAVE IN
                          DELIVERING SUCH PROJECTS?
                          <br />
                           Maximum 1,200 characters.
                          *</b>
                      </label>
                      <br />
                      <textarea
                        name="descriptionExperience"
                        value={descriptionExperience}
                        onChange={e => setDescriptionExperience(e.target.value)}
                        rows="10"
                        maxLength={1200}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="expectedOutcomeOfProject">
                        <b>
                        WHAT ARE THE EXPECTED OUTCOMES OF THE PROJECT? 
                        <br />
                        Maximum 1,500 characters.
                          *</b>
                      </label>
                      <br />
                      <textarea
                        name="expectedOutcomeOfProject"
                        value={expectedOutcomeOfProject}
                        onChange={e => setExpectedOutcomeOfProject(e.target.value)}
                        rows="10"
                        maxLength={1500}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <label htmlFor="descriptionOutcomes">
                        <b>
                          HOW WILL THE OUTCOMES OF THE PROJECT BE EVALUATED?
                          <br />
                          Maximum 1,200 characters.
                          *</b>
                      </label>
                      <br />
                      <textarea
                        name="descriptionOutcomes"
                        value={descriptionOutcomes}
                        onChange={e => setDescriptionOutcomes(e.target.value)}
                        rows="10"
                        maxLength={1200}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <hr className="hr hr2" />
                  </div>
                  <div className="column">
                    <h3>
                      <b>AMOUNT OF FUNDING REQUIRED</b>
                    </h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="totalProjectBudgetAmount">
                        <b>WHAT IS THE TOTAL PROJECT BUDGET AMOUNT? *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="totalProjectBudgetAmount"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={totalProjectBudgetAmount}
                        onChange={e => setTotalProjectBudgetAmount(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="mfRequestAmount">
                        <b>MAZDA FOUNDATION REQUEST AMOUNT(Ex GST)? *</b>
                      </label>
                      <br />
                      <input
                        type="text"
                        name="mfRequestAmount"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={mfRequestAmount}
                        onChange={e => setMFRequestAmount(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                <div className="column">
                    <p>
                      <label htmlFor="otherIncome">
                        <b>OTHER INCOME (PLEASE ADVISE FROM WHERE THIS INCOME WILL BE RECEIVED AND IF IT HAS BEEN CONFIRMED) *</b>
                      </label>
                      <br />
                      </p>
                  </div>
                
                  <div className="one_half column">
                     <input
                        type="text"
                        name="otherIncome"
                        pattern="^\$?[0-9]+\.?[0-9]*$"
                        value={otherIncome}
                        onChange={e => setOtherIncome(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                   
                  </div>
                  </div>
                <div className="columns">
                <div className="column">
                    <p>
                      <label htmlFor="projectExpenditure">
                        <b>
                        PROJECT EXPENDITURE (PLEASE ITEMISE)
                        <br/>
                         Maximum 1,500 characters. *</b>
                      </label>
                      <br />
                      <textarea
                        name="projectExpenditure"
                        value={projectExpenditure}
                        onChange={e => setProjectExpenditure(e.target.value)}
                        rows="15"
                        maxLength={1500}
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                  </div>
                <div className="columns">
                  <div className="one_half column">
                    <p>
                      <label htmlFor="date">
                        <b>DATE PROJECT FUNDS ARE REQUIRED BY *</b>
                      </label>
                      <br />
                      <input
                        type="date"
                        name="date"
                        value={date}
                        onChange={e => setDate(e.target.value)}
                        size="40"
                        required
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <p>
                      NOTES
                      <br /><br />
                      1. By applying for this grant, you permit the Mazda Foundation to use any supplied images in marketing communications.
                      <br /><br />
                      2. Mazda Foundation will acknowledge receipt of your notification but this should not be seen as in any way indicating the notification will progress for further detailed consideration.
                      <br /><br />
                      3. The Foundation does not fund overseas projects/trips in accordance with Australian Taxation Office requirements applying to the Mazda Foundation.
                      <br /><br />
                      4. No attachments should be forwarded with this project notification.
                      <br /><br />
                      <input
                        type="submit"
                        value="Submit"
                        className="button"
                        disabled={result} />
                      <br /><br />
                      {
                        result && (
                          result === "failure" ?
                            <div className="form-failure">Something went wrong. Please try again later.</div>
                            :
                            <div className="form-success">Your message was sent successfully. Thanks.</div>
                        )
                      }
                    </p>
                  </div>
                </div>
                </div>
                }
                {isDeductibleGiftRecipient === "No" && <div>
                <div className="columns">
                  <div className="column">
                  <p>
                  <br />
                      Mazda Foundation can only make distributions or grants to funds, authorities or institutions which have deductible gift recipient status under the Income Tax Assessment Act 1997, 
                      which is why we require evidence that the applicant is endorsed as a “Deductible Gift Recipient” under subdivision 30-BA of that Act.
                  <br /> <br />
                      Please feel free to re-submit your application when the information is available.
                  </p>
                  </div>
                </div>
                </div>
                }
               {/*   */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FundingRequest
